<template>
    <div class="agreement-wrapper">
        <h2 class="agreement_title">
            FREELANCE WRITER AGREEMENT
        </h2>
        <p class="agreement_date">
            UPDATED:
            <span>August 22nd 2022</span>
        </p>
        <p>This update is implemented to emphasize the Company’s commitment to quality. It is the Company’s top priority to provide the best service to its clients by availing the services of competent writers while protecting the interests of both parties.</p>
        <p>All writers admitted to the Company are understood to have completed the screening process and are bound by the stipulations detailed in this document.</p>

        <h2>1. THE QUALITY ASSURANCE TEAM</h2>
        <p>The Company has a Quality Assurance Team (QAT) which consists of Editors and the Quality Assurance Director (QAD). The QAT’s main responsibility is to ensure that papers (or presentations, sheets, and other types of academic project in various formats) sent by writers to clients meet instructions, are of high quality, and adhere to the general standards of academic writing. The QAT carries out this responsibility through the following activities:</p>
        <ul class="list_bullets">
            <li>Evaluating the Writer’s papers</li>
            <li>Instructing the Writer to revise papers as necessary</li>
            <li>Providing the Writer with advice on matters related to writing as necessary</li>
            <li>Implementing disciplinary action as deemed appropriate</li>
            <li>Conducting periodic appraisal of the Writer’s performance</li>
            <li>Any activity the Company deems necessary to uphold quality</li>
        </ul>
        <p>The Writer understands that collaborating with the QAT is an essential part of working in the Company and that the QAT’s actions and decisions represent and are authorized by the Company. The Writer may also seek assistance from the QAT for concerns that may lead to the improvement of the Writer’s papers.</p>
        <p>The Writer is considered as a freelancer, which means that the Writer has the freedom to determine the volume of workload to any extent as long as it does not compromise quality. The Company employs a bidding system and the Writer has the freedom to bid on any order he/she wishes to complete. The Company does not recognize heavy workload as an excuse for poor quality of papers.</p>
        <p>All decisions regarding fines, suspensions, order limit, and account status made by the Quality Assurance Director are final except in cases where new information that could change the decision emerges.</p>

        <h2>2. FRAUD</h2>
        <p>The Writer understands that any act constituting fraud may result in immediate termination of account and expulsion from the website. These acts include, but are not limited to, the following:</p>
        <ol>
            <li>
                <h3>A. PROVIDING FALSE PERSONAL INFORMATION</h3>
                <p>The Writer must provide true and correct personal information requested by the Company as part of the application process. Such information includes, but is not limited to, details such as first name, last name, address, country of residence, phone number, email address, resume, curriculum vitae, other pertinent personal details, and scans of passport, driver's license, and other government-issued ID with personal photo (the scans must be provided in high quality images, with a minimum resolution of 1024x768 pixels). If the Writer is found guilty of submitting false information or forged IDs, the Company shall terminate the account and keep any undisbursed funds.</p>
            </li>
            <li>
                <h3>B. HAVING ANOTHER PERSON COMPLETE THE APPLICATION PROCESS</h3>
                <p>The Writer is solely responsible for completing application including any examination or interview that may be part of the process. The Company has the means to determine if the Writer utilized the aid or services of another person in completing application. If the Writer is found guilty of utilizing the aid or services of another person, the Company shall terminate the account and keep any undisbursed funds.</p>
            </li>
            <li>
                <h3>C. BUYING AND SELLING OF ACCOUNTS</h3>
                <p>The Company strictly prohibits the buying/selling of an account from/to another person. The Company reserves the right to continually verify account ownership to prevent fraudulent activities. If the Writer is found guilty of purchasing an account from a previous owner, selling an account to another person, or giving the account to another person, the Company shall terminate the account and keep any undisbursed funds.</p>
            </li>
            <li>
                <h3>D. ACCOUNT-SHARING</h3>
                <p>
                    The Company implements a one account-one writer policy. Hence, the Company strictly prohibits the Writer from allowing another person to use their account. The Writer is solely responsible for managing his/her account and preventing others from gaining access to the account or acquiring information that can be used to access the account.
                </p>
                <P>
                    Furthermore, the Company strictly prohibits account-sharing, which is when a person other than the Writer completes orders with or without the Writer’s knowledge and permission. Letting another person complete orders jeopardizes consistency and quality. If the Writer is found guilty of sharing his/her account, the Company shall terminate the account and keep any undisbursed funds.
                </P>
            </li>
            <li>
                <h3>E. CONTACTING CLIENTS TO OFFER SERVICES</h3>
                <p>The Company strictly prohibits the Writer from contacting the client with the intention of offering services directly. The Company considers this as a gravely dishonest act as it violates the confidentiality and privacy of clients. All communication between the Writer and clients shall be conducted using the website’s communication system. If the Writer is found guilty of contacting clients to offer services, the Company shall terminate the account and keep any undisbursed funds.</p>
            </li>
        </ol>

        <h2>3. INTELLECTUAL PROPERTY</h2>
        <p>The Writer agrees that the work completed for the Company's clients becomes the sole property of the clients. The Writer likewise guarantees that the completed work will not be reused, resold, or used for any financial gain. The completed work will also not be used in any way that may result in other persons or parties being given the opportunity to claim the work as their own. Prohibitions include, but are not limited to, the following:</p>
        <ol>
            <li>
                <h3>A. Uploading Papers to Another Website</h3>
                <p>The Writer shall not upload papers to other websites. All papers shall be made available only to the client and the Company through authorized channels such as the Company website or the official email of the Company.</p>
            </li>
            <li>
                <h3>B. Leaving Papers Vulnerable to Copying or Theft</h3>
                <p>The Writer shall be solely responsible for keeping papers secure from copying or theft by other persons or parties. The Company shall not accept copying or theft as an excuse when papers fall into the possession of other persons or parties while in the care of the Writer.</p>
            </li>
        </ol>
        <p>The Writer shall be responsible for ensuring the papers remain inaccessible to unauthorized persons or parties. Failure to comply with this requirement may result in termination of the account and/or legal action.</p>

        <h2>4. QUALITY CONTROL</h2>
        <p>The Writer’s papers shall be evaluated based on the following standards and parameters:</p>
        <ol>
            <li>
                <h3>A. ORIGINALITY</h3>
                <p>All papers are scanned through the Company’s plagiarism detection software. The Writer acknowledges that clients may also scan papers through their own plagiarism detection software including that of their educational institution. The Writer is therefore responsible for the originality of the papers provided. All papers must be original with respect to the specifics of the order instructions. The Company guarantees clients that all products provided are free of plagiarism, and thus the Company strictly prohibits any form of plagiarism including, but not limited to, the following:</p>
                <ol>
                    <li>
                        <h4>A.1. Recycling of Papers</h4>
                        <p>The Writer is prohibited from recycling papers including those written for other companies or personal endeavors. Each paper the Writer completes shall be written specifically for an order and in accordance with the instructions. Even in cases where the Writer works on two or more orders with similar instructions, the Writer shall endeavor to produce an original and unique paper for each.</p>
                    </li>
                    <li>
                        <h4>A.2. Light Paraphrasing</h4>
                        <p>The Writer is prohibited from relying on light paraphrasing, otherwise known as mosaic plagiarism in academic parlance. The Company recognizes that paraphrasing is an essential practice in writing, especially in papers that feature summaries, descriptions, and reiteration of information. In such instances, the Writer is expected to rewrite content from sources in his/her own words. Light paraphrasing that yields a similarity index shall be treated as plagiarism except in the presence of mitigating circumstances.</p>
                    </li>
                    <li>
                        <h4>A.3. Excessive Use of Direct Quotes</h4>
                        <p>The Writer is allowed to use direct quotes as necessary and as necessitated by order instructions. The Writer is required to properly cite sources for quoted material. However, the Company prohibits the excessive use of quotes, which must not constitute more than 10% of the total word count unless otherwise required by the order instructions. Excessive use of direct quotes shall be treated by the Company as plagiarism.</p>
                    </li>
                    <li>
                        <h4>A.4. Incorrect/Inadequate Citation</h4>
                        <p>All content from references including direct quotes and paraphrased material shall be properly formatted and cited according to the client’s chosen academic citation style. Failure to cite sources, fabrication of sources, and misattributing information to incorrect sources, among others, shall be treated by the Company as plagiarism.</p>
                    </li>
                </ol>
            </li>
        </ol>
        <p>Failure to ensure the originality of a paper may result in disciplinary action including, but not limited to, warnings, fines, suspension, change of status, change of order limit, suspension, and termination of account.</p>
        <ol>
            <li>
                <h3>B. WORD COUNT</h3>
                <p>The Company sets the standard word count per page to a minimum of 290 words and a maximum of 310 words. Direct quotes will not be considered as part of the word count, except in cases where it is deemed that quotes can be considered as part of the word count such as when explicitly or implicitly stated by the instructions.</p>
                <p>The title page, references page, table of contents, abstract or executive summary, outline and headings and subheadings are also excluded from the word count.</p>
                <p>Appendices may be considered as part of the word count if they contain original text written by the Writer. In such cases, the Writer must inform the QAT of any original appendices included in the paper.</p>
                <p>The Company does not prohibit the Writer from exceeding 300 words per page should the Writer choose to, provided that the instructions do not impose a limit on the word count. However, if the client wishes to cut the word count to just the agreed standard, the Writer shall cater to the request free of charge.</p>
                <p>The Company encourages the Writer to adhere to the standard word count as a way to avoid losses when clients refuse to compensate the Writer for sending papers that exceed the number of pages purchased.</p>
                <ol>
                    <li>
                        <h3>B.1. Requesting Extra Pages</h3>
                        <p>The Writer shall be responsible for ensuring that the word count of the order aligns with the number of pages purchased. The Writer shall determine if extra pages are necessary in a timely manner by checking if the order instructions align with the number of pages purchased. If the word count of the order exceeds the number of pages purchased, the writer may request for extra pages as necessary.</p>
                        <p>The Company will not be able to compel clients to purchase additional pages if the Writer demands extra pages after completing the order without prior timely request conveyed on the Communication Log. It is also the client’s prerogative if he/she wants to purchase any additional pages. If the client is not responding to the extra page request, complete the order within what the client has paid for.</p>
                    </li>
                    <li>
                        <h3>B.2. PowerPoint Presentations</h3>
                        <p>The number of slides that contain substantial content shall match the number of slides purchased by the client.</p>
                        <p>All PowerPoint presentations shall feature speaker notes unless otherwise stated by the instructions. Speaker notes shall be written in a formal manner and shall include citations as necessary.</p>
                        <p>Title slides and references slides are not included in the slide count and word count.</p>
                        <p>The Writer shall be responsible for ensuring that presentations have enough information to guide the client toward successful presentation. Presentations that are too scant or lacking in substantial information shall be sent back for revision.</p>
                        <p>The Writer shall be responsible for ensuring that PowerPoint Presentations are polished and professional-looking.</p>
                        <p>Failure to follow these standards and parameters may result in disciplinary action including, but not limited to, warnings, fines, suspension, change of status, change of order limit, suspension, and termination of account.</p>
                    </li>
                </ol>
            </li>
        </ol>
        <ol>
            <li>
                <h3>C. CONTENT</h3>
                <p>Apart from meeting the order instructions, the Writer shall also be responsible for producing papers that consider other aspects of quality which include, but not limited to, the following:</p>
                <ul class="list_bullets">
                    <li>The paper is appropriate for the academic level indicated;</li>
                    <li>The language of the paper is appropriate for the academic level and on a par with the client’s proficiency;</li>
                    <li>The language of the paper follows the variety of English used by the client (e.g. Standard English, British English, etc.);</li>
                    <li>The discussion is factual and accurate;</li>
                    <li>The discussion exhibits sound reasoning and is free of any potentially offensive and politically incorrect content;</li>
                    <li>The paper exhibits critical thinking, analysis, and synthesis as necessary;</li>
                    <li>The paper makes use of scholarly sources;</li>
                    <li>Works incorporating visual elements (e.g. PowerPoint presentations, brochures, pamphlets, posters, etc.) shall exhibit professional and tasteful design.</li>
                </ul>
            </li>
        </ol>
        <ol>
            <li>
                <h3>D. GENERAL FORMATTING AND ACADEMIC STYLE</h3>
                <p>The Writer shall ensure that papers follow correct formatting and adheres to the correct academic style. The Writer is responsible for learning the various commonly used academic styles including APA, MLA, Chicago, Turabian, Harvard, and ASA among others.</p>
                <p>The Company considers Purdue’s OWL as an excellent guide for academic styles.</p>
                <p>All papers must be manually typed following these specific technical requirements:</p>
                <ul class="list_bullets">
                    <li>Microsoft Office Word in .doc, .docx, or .rtf format</li>
                    <li>Line spacing must be double with no extra space between paragraphs</li>
                    <li>Font style must be Times New Roman</li>
                    <li>Font size must be 12</li>
                    <li>1-inch margins on all sides</li>
                    <li>Page size is 8.5” x 11” (Letter)</li>
                </ul>
                <p>In cases where the order has specific instructions for formatting (e.g. single spacing, size A4, Arial font, etc.), the Writer shall be responsible for following these instructions while maintaining the standard word count per the Company’s policy.</p>
                <p>Failure to follow these standards and parameters may result in disciplinary action including, but not limited to, warnings, fines, suspension, change of status, change of order limit, suspension, and termination of account.</p>
            </li>
        </ol>
        <ol>
            <li>
                <h3>E. LANGUAGE</h3>
                <p>All finished papers must be proofread before uploading. Papers must be free of most or all language errors including, but not limited to, the following:</p>
                <ul class="list_bullets">
                    <li>Grammatical errors</li>
                    <li>Typographical errors</li>
                    <li>Redundancies</li>
                    <li>Misspelled words</li>
                    <li>Inappropriate choice of words</li>
                </ul>
                <p>The level of language must be appropriate to the academic level of the order. Some errors are allowed provided the client specifically requested for these. In such cases, the Writer shall ensure that the errors deliberately featured do not affect the clarity and overall quality of the papers.</p>
                <p>Failure to follow these standards and parameters may result in disciplinary action including, but not limited to, warnings, fines, suspension, change of status, change of order limit, suspension, and termination of account.</p>
            </li>
        </ol>
        <ol>
            <li>
                <h3>F. MEETING THE INSTRUCTIONS</h3>
                <p>The Writer shall be solely responsible for meeting the instructions of and completing an order on or before the deadline.</p>
                <p>The Writer shall exercise due prudence and be responsible for clarifying any issue that may adversely affect the process of completing an order including ambiguous or contradictory instructions and missing course materials among others. The Writer may also enlist the help of the Customer Support Team and the QAT when addressing such issues.</p>
                <p>Failure to follow these standards and parameters may result in disciplinary action including, but not limited to, warnings, fines, suspension, change of status, change of order limit, suspension, and termination of account.</p>
            </li>
        </ol>
        <ol>
            <li>
                <h3>G. DRAFTS FOR BIG ORDERS</h3>
                <p>For big orders (10 pages or more), the Company requires the Writer to provide a draft equivalent to 30% of the order’s total length (e.g. 3-page draft for a 10-page order; 7.5-page draft for a 25-page order; etc.). Drafts are submitted to the Company’s email within the schedule set by the support team for the draft.</p>
                <p>The drafts submitted by the Writer shall be reviewed by the Quality Assurance Team, after which the Writer may expect revision points relayed either through the website’s Communication Log or the company’s e-mail. The Writer is expected to address any concerns or apply suggestions raised by the QAT.</p>
                <p>There are also instances when the client requires a draft. In such instances, the Writer is expected to provide the required drafts accordingly. For orders requiring multiple drafts, the Writer is expected to label each draft properly as well as inform the client accordingly to avoid confusion.</p>
            </li>
        </ol>
        <ol>
            <li>
                <h3>H. REVISIONS</h3>
                <p>The Company guarantees each client that the work will meet the instructions and will be written in accordance to the specified level. The Writer shall be held responsible for correcting the work until all initial requirements are reasonably met.</p>
                <p>Clients expect to receive papers that are correctly done the first time. Failure to follow instructions often result in multiple revisions, refund, and posting of negative reviews. For that reason, it is imperative that all efforts be taken to make the first submission as consistent with the order request as possible. Subsequent revision requests should be completed in a timely manner and should not be delayed.</p>
                <p>Any paper that does not meet instructions may also be returned for revision by anyone from the Customer Support Team and the QAT.</p>
                <p>All revision requests from the client that are in line with the initial instructions for the project must be accommodated all the time. Likewise, all concerns coming from the QAT must be acknowledged by the Writer. Concerns raised by the QAT are meant to help the Writer provide better papers, thereby avoiding negative consequences. Refusing to revise or ignoring issues that are clearly part of the initial instructions shall be used as ground for meting out disciplinary action.</p>
                <p>Should the client add requirements that were not part of or contradictory to the original instructions, the Writer shall have the right to reject such a request. In that case, the Writer must explain the grounds for rejection to the client in a message and notify the Customer Support Team or QAT.</p>
                <p>In the event that a dispute arises between the Writer and the client, the QAT shall be responsible for settling the dispute in a fair manner. If the QAT deems the Writer incapable of revising the paper for any reason regardless of the number of revisions already performed, the order can be reassigned to another writer deemed able to handle the order.</p>
                <p>Failure to follow these standards and parameters may result in disciplinary action including, but not limited to, warnings, fines, suspension, change of status, change of order limit, suspension, and termination of account.</p>
            </li>
        </ol>

        <h2>5. COURSE MATERIALS</h2>
        <p>The Writer is responsible for asking clients to provide course materials (e.g., readings, lectures, notes, books, rubric, templates, etc.) necessary for the completion of orders. In instances where the instructions refer to such course materials yet the client has not provided them, the writer shall call the client’s attention to the matter by sending a message on the Communication Log. The Writer may be held responsible in cases where a paper fails to follow instructions due to the Writer’s failure to request essential course materials.</p>

        <h2>6. TARDINESS</h2>
        <p>The Writer is bound to complete an order on or before the set deadline.</p>
        <p>The Writer is responsible for ascertaining that the time allotted by the client is enough to complete the order satisfactorily. Should the Writer deem the time allowance inadequate, the Writer may negotiate a deadline extension only before accepting the project. Once the client grants an extension, the Writer should place a bid on that order, inform the support team about the new deadlline and consider the new date and time proposed by the client to be the new valid deadline. Deadline extensions can be granted by the client only.</p>
        <p>In line with this, the Company prohibits actions that cause unnecessary delays. These actions include, but are not limited to, the following:</p>
        <ol>
            <li>
                <h3>A. FAILING TO INQUIRE ABOUT ORDER INSTRUCTIONS</h3>
                <p>The Writer shall be responsible for ensuring that instructions are clear, complete, and coherent. The Writer is therefore required to review the instructions the moment a bid is made or the order is assigned. The Company shall not consider unclear, incomplete, or incoherent instructions as a valid excuse if it is apparent the writer failed to exercise due prudence in reviewing the instructions.</p>
            </li>
            <li>
                <h3>B. UPLOADING AN INCOMPLETE FILE</h3>
                <p>The Writer is prohibited from uploading an incomplete file in order to beat the deadline.</p>
            </li>
            <li>
                <h3>C. UPLOADING AN EMPTY FILE</h3>
                <p>The Writer is likewise prohibited from uploading an empty file in order to beat the deadline.</p>
            </li>
            <li>
                <h3>D. UPLOADING A CORRUPTED FILE</h3>
                <p>The Writer is responsible for ensuring that files uploaded to the system can be downloaded, viewed, and utilized by the client and the Company. The Writer must note that the size of files should be kept at 100MB or below, as files that exceed this size will not be properly uploaded into the system.</p>
                <p>The Writer is also responsible to ensure that files are kept safe from being lost or corrupted. The Company does not consider the loss/corruption of files due to technical reasons as a valid excuse for tardiness. The Writer is advised to create backup copies and utilize cloud storage.</p>
            </li>
            <li>
                <h3>E. UPLOADING A DRAFT AS AN ORDER FILE</h3>
                <p>There are instances when the Writer will be asked to provide a draft of a more substantial work either by the client or the Company or both. In cases where the client requests a draft, the Writer shall upload the draft as an Additional File and then inform the client that the draft is sent. Drafts are uploaded as Additional Files so as to avoid giving clients the impression that the order is incomplete. The name of the file must indicate that the uploaded document is a draft. If the order requires multiple drafts, the Writer shall number the draft.</p>
            </li>
            <li>
                <h3>F. DROPPING AN ORDER</h3>
                <p>The practice of dropping projects should be avoided by the Writer because cancellations are detrimental to the workflow. The Writer is therefore advised to assess orders upon bidding to determine if there are potential issues that might hinder timely completion.</p>
                <p>Failure to follow this standard may result in disciplinary action including, but not limited to, warnings, fines, suspension, change of status, change of order limit, suspension, and termination of account.</p>
            </li>
        </ol>

        <h2>7. BIDS</h2>
        <p>The Writer is allowed to bid on any order he/she wishes to complete, although bidding on an order does not guarantee that the said order will be assigned. In the event that the bid is granted, the Writer shall be responsible for completing the order within the time period stipulated on the bid. The Writer shall not ask for deadline extensions. The Company reserves the right to require a Writer to provide proof of the excuse.</p>

        <h2>8. NEGATIVE REVIEW</h2>
        <p>Upon completion of an order, the company asks clients to provide a review of the order. Note that clients score two aspects of the order, which are Delivery and Content. A client’s feedback with a score of 6 or below (for Delivery and/or Content) is considered as a negative review. Such a review shall be subject to evaluation by the QAT for validity. The Company reserves the right to impose sanctions on the Writer’s account such as fines, reduced fees, etc. if the review determines that the Writer has committed infractions or violations of this agreement.</p>

        <h2>9. COMMUNICATION</h2>
        <p>The Writer shall be responsible for communicating in a clear, professional, and prudent manner.</p>
        <ol>
            <li>
                <h3>A. COMMUNICATION WITH CLIENTS</h3>
                <p>The Writer shall be responsible for observing the following guidelines when communicating with clients:</p>
                <ul class="list_bullets">
                    <li>The Writer shall send a welcome message to the client as soon as an order is assigned;</li>
                    <li>All questions asked by the client should be addressed within 12 hours;</li>
                    <li>Arguing with the client is prohibited; any disagreement between the Writer and the client should be forwarded to the Client Support Team and QAT who will serve as mediators;</li>
                    <li>Under no circumstances will foul behavior be tolerated;</li>
                    <li>All communication shall be kept polite and professional;</li>
                    <li>Under no circumstances will the Writer solicit details from the client such as email address, telephone number, full name, social media accounts, and any personally identifiable information except in cases in which such details are deemed vital to the completion of an order.</li>
                </ul>
            </li>
            <li>
                <h3>B. COMMUNICATION WITH THE CLIENT SUPPORT TEAM</h3>
                <p>The Writer shall be responsible for observing the following guidelines when communicating with the Client Support Team:</p>
                <ul class="list_bullets">
                    <li>The Writer should respond to all messages sent by Operators as soon as they are seen;</li>
                    <li>Confirmation for orders assigned following the Writer’s bid should be made a priority to enable the Operators to monitor progress (response is needed within 6 hours);</li>
                    <li>Confirmation for revision request should be made a priority (response is needed within 12 hours);</li>
                    <li>All missed calls/voicemails left by the Operators should be responded to as soon as possible;</li>
                    <li>All communication should be kept polite and professional;</li>
                    <li>The Company prohibits the exchange of information not necessary to the performance of duties between the Writer and the Operators (e.g., social media accounts, bank details, etc.);</li>
                    <li>Advice offered by the Operators should be taken into consideration;</li>
                    <li>Disagreements and disputes between the Writer and the Operators will be forwarded to the upper management.</li>
                </ul>
            </li>
            <li>
                <h3>C. COMMUNICATION WITH THE QUALITY ASSURANCE TEAM</h3>
                <p>The Writer shall be responsible for observing the following guidelines when communicating with the Quality Assurance Team:</p>
                <ul class="list_bullets">
                    <li>All communication should be kept polite and professional</li>
                    <li>Revision requests lodged by Editors should be responded to as soon as possible</li>
                    <li>Disagreements and disputes between the Writer and the Editors will be settled by the Quality Assurance Director</li>
                    <li>The Writer shall take Editors’ review, comments, and advice seriously</li>
                    <li>The Writer shall address in a prompt manner all of the QAD’s questions regarding revisions, writer performance, late orders, dropped orders, overdue orders, orders completed with poor quality, etc.</li>
                </ul>
                <p>Failure to follow these guidelines may result in disciplinary action including, but not limited to, warnings, fines, suspension, change of status, change of order limit, suspension, and termination of account.</p>
            </li>
        </ol>
    </div>
</template>

<script>
// VUEX
import {
    mapWriterFields
} from '@/store/modules/writer';

export default {
    computed: {
        ...mapWriterFields([
            'writer_data'
        ])
    }
}
</script>

<style lang="scss" scoped>

.agreement-wrapper {
    max-width: 1200px;
    margin: 0 auto;
    color: #181818;
    padding: 10px 40px;
    position: relative;
    top:-30px;
    background-color: white;
    line-height: 30px;
    font-size: 18px;

    h2, h3 {
        margin: 30px 0 15px;
    }
    p {
        margin: 15px 0;
    }

    ul, ol {
        padding-left: 30px;
    }

    .list_bullets {
        list-style-type: disc;
        padding-left: 40px;
    }

    .agreement_title {
        text-align: center;
        margin-top: 0;
    }

    .agreement_date {
        text-align: center;
        font-weight: 500;
        span {
            font-weight: 400;
        }
    }
}

@media (max-width: 1200px){
    .agreement-wrapper  {
        padding: 30px 20px;
    }
}
</style>
